import styled from "styled-components";
import hero from "../../assets/images/hero.png";
import smartHero from "../../assets/images/smartHero.png";

export const HeroContainer = styled.div`
  background: url(${smartHero}) no-repeat center center;
  position: relative;
  background-size: cover;
  height: 100vh;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #09417a; /* Overlay color */
    opacity: 0.7; /* Adjust the opacity value as needed */
  }

  @media screen and (max-width: 1000px) {
    background: #001e40;
    height: auto;
  }
`;

export const HeroPageWrapper = styled.div`
  width: 60vw;
  margin: auto;
  display: flex;
  padding: 80px 0 0 0;
  justify-content: space-between;
  .hero-container {
    text-align: center;
    @keyframes slideInRight {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0);
      }
    }
    @media screen and (max-width: 1000px) {
      margin-bottom: 40px;
  }

    /* animation: slideInRight 0.5s ease-out; */
  }
  @media screen and (max-width: 600px) {
    width: auto;
    text-align: center;
    padding: 0 20px;
  }

  .hero-container {
    @media screen and (min-width: 800px) {
      flex: 0.7;
    }
  }
`;

export const HeroImageContainer = styled.div`
  height: 800px;
  width: 500px;

  @keyframes slideInLeft {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  /* animation: slideInLeft 0.5s ease-out; */

  img {
    height: auto;
    width: 100%;
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const HeroHeadingText = styled.h1`
  color: #fff;
  font-size: 63.686px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media screen and (max-width: 600px) {
    font-size: 40px;
  }
`;

export const TopUpSpan = styled.span`
  display: inline-block;
  /* padding; */
  background: #fff;
  color: #001e40;
`;

export const HeroParagraph = styled.p`
  color: #fff;
  font-size: 18.635px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DownloadButton = styled.button`
  color: #001e40;

  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  padding: 0px 10px;
  border-radius: 5px;
  background: #fff;

  outline: none;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

export const LoginButton = styled.a`
  color: #001e40;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;

  padding: 5px 20px;
  border-radius: 5px;
  background: #fff;

  outline: none;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;
