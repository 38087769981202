import Spacer from "./Spacer";
import styled from "styled-components";

const SectionBar = styled.div`

  background: #094E8E;;
  height: 14px;
  width: 40px;


  @media only screen and (max-width: 768px) {
     margin: auto;
}
`;

const SectionHead = styled.h1`
  color: #094E8E;;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* color: #001E40; */

  @media only screen and (max-width: 768px) {
      text-align: center;
}

`;
const SectionParagraph = styled.p`
  color: #094E8E;;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  @media only screen and (max-width: 768px) {
      text-align: center;
}
`;

const SectionTitle = ({ title, text }) => {
    return (
        <>
        <SectionBar></SectionBar>
        <Spacer vertical space={10} />
        <SectionHead>{title}</SectionHead>
        <Spacer vertical space={10} />
        <SectionParagraph>{text}</SectionParagraph>
      </>
    )
 
};

export default SectionTitle
