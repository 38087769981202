import { useEffect, useRef } from "react";
import styled from "styled-components";

import {
  monnifyLogo,
  mtnLogo,
  airtelLogo,
  MobileLogo,
  gloLogo,
  waecLogo,
  dstvLogo,
  startimesLogo,
  kedcoLogo,
  jedco,
  goTvLogo,
  k,
} from "../../assets/images";

const OurPartnersRoot = styled.div`
  /* margin: auto; */
  padding: 40px 0;
`;

const OurPartnersHeader = styled.div`
  text-align: center;
  font-size: 48px;
  font-weight: bold;
  align-self: center;
  color: #094E8E;
`;

const LogoSectionWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  

`

const LogosSection = styled.div`
  margin-top: 40px;
  animation: 35s slide infinite linear;
  display: inline-block;
  @keyframes slide {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }

  /* &:hover {
    animation-play-state: paused;

  } */


`;
const LogoContainer = styled.div`
  display: inline-block;
  width: 52px;
  height: 53px;
  transition: transform 0.2s;
  margin: 0 50px;

  img {
    height: auto;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.5);

  }
`;



const OurPartners = () => {

  const containerRef = useRef(null);
  const slideRef = useRef(null);

  useEffect(() => {
    const cloneSlide = slideRef.current.cloneNode(true);
    containerRef.current.appendChild(cloneSlide);
  }, []);

  return (
    <OurPartnersRoot>
      <OurPartnersHeader>Our partners</OurPartnersHeader>
      <LogoSectionWrapper  ref={containerRef}>
        <LogosSection ref={slideRef}>
          <LogoContainerComp src={monnifyLogo} />
          <LogoContainerComp src={mtnLogo} />
          <LogoContainerComp src={airtelLogo} />
          <LogoContainerComp src={MobileLogo} />
          <LogoContainerComp src={gloLogo} />
          <LogoContainerComp src={waecLogo} />
          <LogoContainerComp src={dstvLogo} />
          <LogoContainerComp src={startimesLogo} />
          <LogoContainerComp src={kedcoLogo} />
          <LogoContainerComp src={jedco} />
          <LogoContainerComp src={goTvLogo} />
          <LogoContainerComp src={k} />
        </LogosSection>
      </LogoSectionWrapper >
    </OurPartnersRoot>
  );
};

export default OurPartners;

const LogoContainerComp = ({ src }) => {
  return (
    <LogoContainer>
      <img src={src} alt="partner" />
    </LogoContainer>
  );
};
