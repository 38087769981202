import {
  HeroContainer,
  HeroPageWrapper,
  HeroHeadingText,
  HeroParagraph,
  DownloadButton,
  LoginButton,
} from "./styles";
import heroImage from "../../assets/images/heroIphone.png";
import playStoreLogo from "../../assets/images/play-store.svg";
import appStoreLogo from "../../assets/images/app-store.svg";
import Spacer from "../base/Spacer";

import { Slide } from "react-awesome-reveal";

const Hero = () => {
  return (
    <HeroContainer id="home">
      <HeroPageWrapper>
        <Slide direction="left" cascade={true}>
          <div className="hero-container">
            <HeroHeadingText>
              The Top choice for Virtual Top-Up services
            </HeroHeadingText>
            {/* <Spacer vertical={true} space={10} /> */}
            <HeroParagraph>
              We offer a wide range of mobile network operators and service
              providers, enabling users to recharge airtime and data across
              various networks with ease.
            </HeroParagraph>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.x.smart_data"
                target="_blank"
                style={{ display: "inline-block", marginRight: "15px" }}
              >
                <img src={playStoreLogo} alt="play-store" />
              </a>
              <a
                href="https://apps.apple.com/ng/app/smartdatalinks-pro/id6474259559"
                target="_blank"
              >
                <img src={appStoreLogo} alt="play-store" />
              </a>
            </div>
            <Spacer vertical={true} space={30} />
            <DownloadButton>Download Now</DownloadButton>
            <Spacer vertical={true} space={30} />
            <LoginButton href="https://app.smartdatalinks.ng/register">
              Get Started
            </LoginButton>
            <Spacer vertical space="40px" />
          </div>
        </Slide>
        {/* <Slide direction="right">
          <HeroImageContainer>
            <img src={heroImage} alt="hero" />
          </HeroImageContainer>
        </Slide> */}
      </HeroPageWrapper>
    </HeroContainer>
  );
};

export default Hero;
