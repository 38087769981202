import Spacer from "../base/Spacer";

import {
 WhyChooseUsRoot,
 WhyChooseUsHeader,
 WhyChooseUsSubHeader,
 WhyChooseRoot,
 CardContainer,
 Image,
 Title,
 Description
} from "./styles"
import clock from "../../assets/images/clock.png";
import reliability from "../../assets/images/reliable.png";
import security from "../../assets/images/security.png";






const WhyChooseUs = () => {
  return (
    <WhyChooseUsRoot>
      <WhyChooseUsHeader>Why Choose Us</WhyChooseUsHeader>
      <Spacer vertical space={24} />
      <WhyChooseUsSubHeader>
        We serve as a dependable vending platform for airtime recharges, the
        acquisition of data bundles, and the payment of utility bills in
        Nigeria.
      </WhyChooseUsSubHeader>
      <Spacer vertical space={48} />
      <WhyChooseRoot>
        <Card
          imageUrl={clock}
          title="Quick Delivery"
          description="Smart Data Links employ advanced technology to power our services. 
          Automation drives our data delivery and wallet funding,
           ensuring that airtime top-ups and data purchases occur seamlessly 
           and are delivered to you in nearly real-time."
        />
        <Card
          imageUrl={reliability}
          title="We Are Reliable"
          description="Smart Data Links is a thoroughly optimized platform known for its reliability and dependability. You receive 100% value for every transaction conducted with us."
        />
        <Card
          imageUrl={security}
          title="Concete Security"
          description="Your funds are safeguarded by your e-wallet PIN and can be securely stored for any duration you prefer. Additionally, you have the flexibility to withdraw your funds at any time."
        />
      </WhyChooseRoot>
    </WhyChooseUsRoot>
  );
};

export default WhyChooseUs;

const Card = ({ imageUrl, title, description, ...restStyles }) => {
  return (
    <CardContainer {...restStyles}>
      <Image imageUrl={imageUrl}>
        <img src={imageUrl} alt={title} />
      </Image>
      <Spacer vertical space={40} />
      <div>
        <Title>{title}</Title>
        <Spacer vertical space={10} />
        <Description>{description}</Description>
      </div>
    </CardContainer>
  );
};
