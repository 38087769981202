import { styled } from "styled-components";

export const WhyChooseUsRoot = styled.div`
  width: 80vw;
  margin: auto;
  padding: 40px 0;
`;

export const WhyChooseUsHeader = styled.div`
  text-align: center;
  font-size: 48px;
  font-weight: bold;
  align-self: center;
  color: #094E8E;
`;

export const WhyChooseUsSubHeader = styled.div`
  text-align: center;
  font-size: 18px;
  align-self: center;
  color: #001e40;
`;

export const CardContainer = styled.div`
  overflow: hidden;
  background-color: #eef7fd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 27px 30px;
  border-radius: 20px;
`;

export const Image = styled.div`
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  flex-shrink: 0;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #001E40;;
  font-family: "Poppins";
`;

export const Description = styled.div`
  text-align: center;
  font-size: 14px;
  color: #001E40;;
  width: 200px;
`;

export const WhyChooseRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
  padding: 4px 0;

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
`;